export const messages = {
  'lineChart.start': 'Start',
  'lineChart.finish': 'Finish',
  'lineChart.average': 'Avg.',
  'lineChart.bestTime': (time: string) => `Best time ${time}`,
  'lineChart.averageTime': (time: string) => `All users avg. ${time}`,
  'lineChart.filter.version': 'Version',
  'lineChart.filter.mode': 'Mode',
  'lineChart.filter.platform': 'Platform',
  'lineChart.YAxis.label': 'Time (min)',
  'analyticsPage.pageTitle': 'Completed procedures',
  'analyticsPage.proceduresExecution': 'Procedures execution',
  'analyticsPage.noUsersInGroup': 'There are no users in this group',
  'executionsProgress.completed': (count: number) => `Completed ${count} / `,
  'executionsProgress.assigned': (count: number) => ` ${count} assigned ${count == 1 ? 'procedure' : 'procedures'}`,
  'pieChart.activeShape.title': (count: number) => `Completed ${count == 1 ? 'procedure' : 'procedures'}`,
  'pieChart.noData': 'No data',
  'pieChart.completed': 'Completed',
  'pieChart.notExecuted': 'Not executed',
  'analyticsSidebar.allUsers': 'All users',
  'analyticsSidebar.usersWithoutGroups': 'Ungrouped users',
  'analyticsFilter.lastWeek': 'Last week',
  'analyticsFilter.lastMonth': 'Last month',
  'analyticsFilter.lastThreeMonths': 'Last three months',
  'analyticsFilter.lastYear': 'Last year',
  'analyticsFilter.allTime': 'All time',
  'analyticsFilter.title': 'Completed procedures',
  'analyticsFilter.dateRange': 'Date range',
  'analyticsFilter.apply': 'Apply',
  'analyticsSummary.backToList': 'Back to users list',
  'analyticsSummary.assigned': 'Assigned',
  'analyticsSummary.completed': 'Completed',
  'analyticsSummary.inProgress': 'In progress',
  'analyticsSummary.proceduresCount': (count: any) => `${count} ${count == 1 ? 'Procedure' : 'Procedures'}`,
}
