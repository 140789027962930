import { Instance, SnapshotIn, types, flow, getEnv, cast } from 'mobx-state-tree';

import { IStoresEnv } from '@core/storesEnv';
import { CompanyStore } from './Company.store';
import { CreateCompanyStore } from './CreateCompany.store';

import { GetCompaniesParams, performGetCompanies } from 'Companies/services';
import {paginationStatusInitialState, PaginationStatusStore } from 'Users/stores/PaginationStatus.store';

export const CompaniesGridStoreInferred = types
  .model('CompaniesGridStoreInferred', {
    companiesList: types.array(CompanyStore),
    createCompany: types.optional(CreateCompanyStore, { dataIsolation: false }),
    paginationStatus: types.optional(PaginationStatusStore, {}),
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    const loadCompanies = flow(function* (payload: GetCompaniesParams) {
      const result = yield performGetCompanies(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
        },
      );
      if (result.success) {
        self.companiesList = result.data.entities?result.data.entities:[];
        self.paginationStatus = result.data.meta?result.data.meta:paginationStatusInitialState;
      }
    });

    const cleanData = () => {
      self.companiesList = cast([]);
    };

    return {
      loadCompanies,
      cleanData,
    }
  })

type CompaniesGridStoreFactoryType = typeof CompaniesGridStoreInferred;
interface ICompaniesGridStoreFactory extends CompaniesGridStoreFactoryType {}
export const CompaniesGridStore: ICompaniesGridStoreFactory = CompaniesGridStoreInferred;
export interface ICompaniesGridStore extends Instance<ICompaniesGridStoreFactory> {}
export interface ICompaniesGridStoreSnapshotIn extends SnapshotIn<ICompaniesGridStore> {}
