import { Instance, SnapshotIn, types, flow, getEnv } from 'mobx-state-tree';

import { BaseItem } from '@shared/stores/BaseItem.store';
import {
  performEditCompany,
  performGetCompany,
  performDeleteCompany,
} from 'Companies/services';
import { IStoresEnv } from '@core/storesEnv';

export const userInitialState = {
  id: '',
  name: '',
  description: '',
  dataIsolation: false,
  allowStandaloneBuild:false,
  expireAt:'',
  createdAt:'',
  maxGroups:9999,
  maxProcedures:9999,
  maxLicensesCreator:9999,
  maxLicensesPlayer:9999,
  maxUsers:9999,
  admins:[]
}

export const CompanyStoreInferred = types
  .model('CompanyStoreInferred', {
    id: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    description: types.maybeNull(types.string),
    dataIsolation: types.optional(types.boolean, false),
    allowStandaloneBuild: types.maybeNull(types.boolean),
    expireAt: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    maxGroups: types.optional(types.integer, 9999),
    maxProcedures: types.optional(types.integer, 9999),
    maxLicensesCreator: types.optional(types.integer, 9999),
    maxLicensesPlayer: types.optional(types.integer, 9999),
    maxUsers: types.optional(types.integer, 9999),
    admins:types.optional(types.array(types.string),[])
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);
    const editCompany = flow(function* (payload: any) {
      if(payload.expireAt){
        var expireAt=new Date(payload.expireAt);
        expireAt.setUTCHours(23);
        expireAt.setUTCMinutes(59);
        expireAt.setUTCSeconds(59);
        payload.expireAt=expireAt.toUTCString();
      }
      const result = yield performEditCompany(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
          disableDefaultErrorHandlers: true,
        },
      );

      return result;
    });

    const loadCompany = flow(function* (payload: any) {
      const result = yield performGetCompany(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
        },
      );

      return result;
    });

    const deleteCompany = flow(function* (payload: any) {
      const result = yield performDeleteCompany(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
        },
      );

      return result;
    });

    return {
      editCompany,
      loadCompany,
      deleteCompany,
    }
  })

type CompanyStoreFactoryType = typeof CompanyStoreInferred;
interface ICompanyStoreFactory extends CompanyStoreFactoryType {}
export const CompanyStore: ICompanyStoreFactory = CompanyStoreInferred;
export interface ICompanyStore extends Instance<ICompanyStoreFactory> {}
export interface ICompanyStoreSnapshotIn extends SnapshotIn<ICompanyStore> {}
