import { FormSchema } from '@shared/components/forms/FormSchema';
import { messages } from '../register.messages';

import { FormValues } from './FormValues';

type RegisterFormSchema = FormSchema<FormValues>;

export const valuesSchema: RegisterFormSchema = {
  email: {
    fieldName: 'email',
    label: messages['registerForm.email.label'],
    isRequired: true,
    type: 'email',
  },
  companyName:{
    fieldName: 'companyName',
    label: messages['registerForm.companyName.label'],
    isRequired: true,
  },
  companyDescription:{
    fieldName: 'companyDescription',
    label: messages['registerForm.companyDescription.label'],
    isRequired: false,
  },
  department:{
    fieldName: 'department',
    label: messages['registerForm.department.label'],
    isRequired: false,
  },
  name:{
    fieldName: 'name',
    label: messages['registerForm.name.label'],
    isRequired: true,
  },
  surname:{
    fieldName: 'surname',
    label: messages['registerForm.surname.label'],
    isRequired: true,
  } 
};
