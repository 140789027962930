import { createUseStyles } from 'react-jss';
import color from 'color';

import { ThemeProps } from '@styles/theme';
import { flex, textOverflowDots } from '@resources/mixins';
import { styled } from '@material-ui/core';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  expanded: {},
  notActive: {},
  body: {
    display: 'flex',
    padding: [theme.spacing(4), 0, theme.spacing(4), theme.spacing(11.5)],
  },

  header: {
    color: theme.colors.grey_text,
    fontSize: 17,
    fontFamily: theme.font.secondary,
    lineHeight: 1.25,
    paddingBottom: 10,
  },
  subheader:{
    color: theme.colors.grey_text,
    fontSize: 16,
    fontFamily: theme.font.secondary,
    lineHeight: 1.25,
    paddingBottom: 10,
  },
  title: {
    fontFamily: theme.font.secondary,
    fontWeight: 'normal',
    fontSize: 24,
    padding: [30, 0],
    lineHeight: 1.33,
    textAlign: 'center',
    color: theme.colors.grey_text,
    border: `1.5px solid ${theme.colors.grey_light}`,
    background: theme.colors.white,
    width: '100%',
    whiteSpace: 'pre-line',
    marginTop: -80,
    marginBottom: 10,
  },

  emailWrapper: {
    width: 443,
  },

  groupsWrapper: {
    width: 304,
  },

  text: {
    color: theme.colors.black,
    lineHeight: 1.5,
    fontSize: 16,
    fontFamily: theme.font.primary,
  },

  expandIcon: {
    transformOrigin: 'center',
    transition: 'transform .2s ease',
    transform: 'rotate(90deg)',

    '&$expanded': {
      transform: 'rotate(-90deg)',
    },
  },

  button: {
    border: 'none',
    background: 'none',
    outline: 'none',
    padding: 0,
    height: 24,
    width: 24,
    ...flex('row', 'center', 'center'),

    '&:disabled': {
      opacity: .3,
    },

    '&:hover': {
      background: theme.colors.icon_background,
      borderRadius: theme.radius / 2,
      cursor: 'pointer',
    },
  },

  buttonCreate: {
    width: 'auto',
    height: 'auto',
    fontFamily: theme.font.secondary,
    fontSize: 20,
    lineHeight: 1.4,
    fontWeight: 'normal',
    padding: [10, 8],
    borderRadius: theme.radius / 2,
    background: theme.colors.icon_background,
    color: theme.colors.violet_primary,
    '&:hover': {
      background: theme.colors.transp_violet,
    },
  },

  marginLeft: {
    marginLeft: 10,
  },

  buttonTakeover: {
    width: 26,
    height: 26,
  },

  actionIcon: {
    width: 26,
    height: 26,
  },

  userWrapper: {
    ...flex('row', 'flex-start', 'center'),
  },

  userAvatar: {
    display: 'inline-flex',
    marginRight: theme.spacing(3),
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },

  tableActions: {
    ...flex('row', 'space-between', 'center'),
    padding: [0, theme.spacing(4)],
    marginBottom: theme.spacing(4),
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
  },
  invite: {
    display: 'flex',
    alignItems: 'center',
  },
  h4: {
    fontFamily: theme.font.secondary,
    fontSize: 20,
    lineHeight: 1.4,
    fontWeight: 'normal',
    color: theme.colors.black,
  },
  addIcon: {
    color: theme.colors.violet_primary,
    cursor: 'pointer',
    marginLeft: theme.spacing(3),
  },

  filterIcon: {
    color: theme.colors.violet_primary,
    cursor: 'pointer',
  },

  searchWrapper: {
    width: 312,
    marginRight: -theme.spacing(4),
  },

  moreItemsPresent: {
    color: theme.colors.blue_link,
    marginLeft: theme.spacing(2),
    fontFamily: theme.font.secondary,
  },

  noInfo: {
    color: theme.colors.grey_text,
    ...textOverflowDots(),
    display: 'inherit',
  },

  userStatus: {
    padding: [theme.spacing(1), theme.spacing(3)],
    borderRadius: theme.radius,
    color: theme.colors.violet_primary,
    backgroundColor: `${color(theme.colors.violet_primary).fade(0.92)}`,

    '&$notActive': {
      color: theme.colors.grey_text,
      backgroundColor: `${color(theme.colors.grey_lighter).fade(0.35)}`,
    },
  },

  capitalized: {
    textTransform: 'capitalize',
  },

  ellipsis: {
    ...textOverflowDots(),
  },
  nameWrapper: {
    ...flex('row', 'center', 'center'),
  },
  companyName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth:'200px',
    whiteSpace:'nowrap'
  },
  bodySubComponent: {
    display: 'flex',
    flexDirection: 'column',
    padding: [theme.spacing(4), theme.spacing(4), theme.spacing(4), theme.spacing(10)],
  },
  descriptionWrapper: {
    padding: [0, theme.spacing(2), theme.spacing(4), theme.spacing(2)],
    flex: '18 0 18%',
    flexDirection:'row'
  },
  optionWrapper:{
    width:'20%',
    display:'inline-block',
  }
}));
