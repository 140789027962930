import { Instance, SnapshotIn, types, flow, getEnv, getSnapshot, applySnapshot } from 'mobx-state-tree';
import { IStoresEnv } from '@core/storesEnv';

import {
  performCreateCompany,
} from 'Companies/services';

export const createCompanyInitialState = {
  name: '',
  description: '',
  dataIsolation: false,
  allowStandaloneBuild :false,
  expireAt: '',
  errors: null,
}

export type CreateCompanyParams = {
  name?: string,
  description?: string,
  dataIsolation: boolean,
  allowStandaloneBuild: boolean,
  expireAt?: string,
}

export type CreateCompanyStoreParams = CreateCompanyParams & { errors: string[] | null }

export const CreateCompanyStoreInferred = types
  .model('CreateCompanyStoreInferred',
    {
      name: types.optional(types.string, ''),
      description: types.optional(types.string, ''),
      dataIsolation: types.boolean,
      allowStandaloneBuild:  types.maybeNull(types.boolean),
      expireAt: types.maybeNull(types.string),
      errors: types.maybeNull(types.array(types.string)),
    })
  .views(self => ({
    get getCompanyFields() {
      return getSnapshot(self);
    },
  }))
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    const createCompanyRequest = flow(function* (payload: any) {      
      if(payload.expireAt){
        var expireAt=new Date(payload.expireAt);
        expireAt.setUTCHours(23);
        expireAt.setUTCMinutes(59);
        expireAt.setUTCSeconds(59);
        payload.expireAt=expireAt.toUTCString();
      }
      const result = yield performCreateCompany(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
          disableDefaultErrorHandlers: true,
        },
      );

      if (!result.success) {
        const { body } = result.error || {};

        self.errors = body?.errors;
      } else {
        self.errors = null;
      }

      return result;
    });

    const setCompanyFields = (params: CreateCompanyStoreParams) => {
      applySnapshot(self, params);
    }

    return {
      createCompanyRequest,
      setCompanyFields,
    }
  })

type CreateCompanyStoreFactoryType = typeof CreateCompanyStoreInferred;
interface ICreateCompanyStoreFactory extends CreateCompanyStoreFactoryType {}
export const CreateCompanyStore: ICreateCompanyStoreFactory = CreateCompanyStoreInferred;
export interface ICreateCompanyStore extends Instance<ICreateCompanyStoreFactory> {}
export interface ICreateCompanyStoreSnapshotIn extends SnapshotIn<ICreateCompanyStore> {}
