import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';
import { StringSchema } from 'yup';

export type GetUserProcedureStepsParams = {
  procedureId: string;
  procedureVersionId: string;
  executionMode:string;
  procedureVersionPlatformId: string;
  userId: string;
};

export const performGetUserProcedureSteps = async (
  api: IApiStore, params: EndpointCallParams<GetUserProcedureStepsParams>
) => {
  const { payload, ...rest } = params;

  const req = api
    .post('analytic-app/api/v1/Analytics/ProcedureUserAnalytic', {
      payload,
      ...rest,
    })

  return req;
}
