import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export const performGetAdminsList = async (api: IApiStore, params: EndpointCallParams<{}>) => {
  const { errorHandlers } = params;
  const req = api
    .get('identity-app/api/v1/Filters/Admins', {
      errorHandlers,
      payload: {},
    })

  return req;
}