import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { DownloadableVersionFileStore } from './DownloadableVersionFile.store';

export const DownloadableStoreInferred = types.model('DownloadableStore', {
  id: types.identifier,
  name: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  downloadableVersionFiles: types.optional(types.array(DownloadableVersionFileStore),[])
});

type DownloadableStoreFactoryType = typeof DownloadableStoreInferred;
interface IDownloadableStoreFactory extends DownloadableStoreFactoryType {}
export const DownloadableStore: IDownloadableStoreFactory = DownloadableStoreInferred;
export interface IDownloadableStore extends Instance<IDownloadableStoreFactory> {}
export interface IDownloadableStoreSnapshotIn extends SnapshotIn<IDownloadableStore> {}
