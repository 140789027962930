import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import { useTheme } from 'react-jss';
import { Cell, Column } from 'react-table';
import classNames from 'classnames';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';

import {
  useProceduresUIStore, useProcedureUIStore,
  useUserPermissionsUIStore
} from '@core/useStores';
import { DATE_MMM_DD_YYYY_HH_MM } from '@shared/constants';
import { Table } from '@shared/components/Table';
import { SearchForm } from '@shared/components/SearchForm';
import { NoResultsView } from '@shared/components/NoResultsView';
import { ExpandableList } from '@shared/components/ExpandableList';
import ArrowRightIcon from '@assets/arrow-right.svg';
import ProceduresPlaceholder from '@assets/procedures-placeholder.svg';
import EditIcon from '@assets/edit.svg';

import { ConfirmationModal } from '@shared/components/ConfirmationModal';
import { ToastMessage } from '@shared/components/Toast';

import { IPageQueryStoreSnapshotIn } from 'Procedures/stores';
import { ProcedureListingDevice } from '../../domain/Device';
import { deviceTypesImages } from '../../helpers/deviceTypesImages';
import { ProceduresFiltersModal } from '../ProceduresFiltersModal';
import { ProceduresFiltersBar } from '../ProceduresFiltersBar';
import { NoProceduresInGroup } from '../NoProceduresInGroup';
import { ProceduresSummary } from '../ProceduresSummary';
import { ProcedureModal } from '../ProcedureModal';

import { useStyles } from './ProceduresPage.styles';
import { messages } from 'Procedures/procedures.messages';

type SubComponentProps = {
  row: {
    originalSubRows: Array<{
      description: string,
      access: {
        id: string,
        name: string,
      }[],
      devices: {
        id: string,
        name: string,
      }[],
      history: {
        date: string,
        description: string,
      }[],
    }>,
  }
}

type FetchDataType = {
  pageIndex: number;
  sortBy: {
    id: string;
    desc: boolean;
  }[]
}

type ProcedureMedia = {
  src: string;
  description: string;
}

const EXPANDER_CELL_WIDTH = 24;
const MAX_VISIBLE_MODES = 1;

const NoInfo = () => {
  const styles = useStyles();

  return <div className={styles.noInfo}>{messages['proceduresPage.noInfo']}</div>
}

const columnsData = [
  {
    id: 'expander',
    minWidth: 40,
    maxWidth: 40,
    Header: () => (
      <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
    ),
    Cell: ({ row }: Cell) => {
      const { style, ...props } = row.getToggleRowExpandedProps();
      const styles = useStyles();

      return (
        <div
          {...props}
          style={{
            ...style,
            minWidth: EXPANDER_CELL_WIDTH,
            display: 'flex',
          }}
        >
          {row.canExpand && (
            <img
              src={ArrowRightIcon}
              className={classNames(styles.expandIcon, { [styles.expanded]: row.isExpanded })}
            />
          )}
        </div>
      )
    },
    width: EXPANDER_CELL_WIDTH + 16,
    disableSortBy: true,
  },
  {
    Header: messages['proceduresPage.table.header.name'],
    accessor: 'name',
    width: 18,
    maxWidth: 300,
    Cell: ({ row, value }: Cell) => {
      const styles = useStyles();
      /*
      const { procedureMedias } = row.original as {
        procedureMedias: ProcedureMedia[]
      };
      const media = procedureMedias.length && procedureMedias[0];
      */
      const { procedurePreview } =  row.original as {
        procedurePreview: ProcedureMedia
      };
      const media = procedurePreview;
      
      return (
        <div className={styles.nameWrapper}>
          {
            media
              ? (
                <img
                  className={styles.procedureImage}
                  src={media.src}
                  alt={media.description}
                />
              )
              : (
                <div className={styles.procedureImage}>
                  <img src={ProceduresPlaceholder} />
                </div>
              )
          }

          <span className={styles.procedureName}>{ value }</span>
        </div>
      );
    },
  },
  {
    Header: messages['proceduresPage.table.header.devices'],
    accessor: 'devices',
    width: 15,
    maxWidth: 150,
    disableSortBy: true,
    Cell: ({ value }: Cell) => {
      const values = _.uniqBy(value, (device: ProcedureListingDevice) => device.type);

      if (!values.length) {
        return <NoInfo />;
      }

      return (
        <div>
          {
            values.map((device: ProcedureListingDevice, index: number) =>
              <img
                src={deviceTypesImages[device.type]}
                alt={device.platform}
                title={device.type}
                key={index}
              />
            )
          }
        </div>
      );
    },
  },
  {
    Header: messages['proceduresPage.table.header.availableModes'],
    accessor: 'availableModes',
    width: 19,
    maxWidth: 170,
    Cell: ({ value }: Cell) => {
      if (!value && !value.length) {
        return <NoInfo />;
      }

      const renderValue = value.join(', ');

      return (
        <span title={renderValue}>
          {value.length > MAX_VISIBLE_MODES
            ? `${value[0]}, ...`
            : renderValue}
        </span>
      );
    },
  },
  {
    Header: messages['proceduresPage.table.header.type'],
    accessor: 'type',
    width: 12,
    maxWidth: 120,
    Cell: ({ value }: Cell) => value || <NoInfo />,
  },
  {
    Header: messages['proceduresPage.table.header.collaboration'],
    accessor: 'collaboration',
    width: 14,
    maxWidth: 150,
    Cell: ({ value }: Cell) => {
      return value ? messages['proceduresPage.available'] : messages['proceduresPage.notAvailable'];
    },
  },
  {
    Header: messages['proceduresPage.table.header.executionsCount'],
    accessor: 'executionsCount',
    width: 12,
    maxWidth: 130,
    Cell: ({ value }: Cell) => {
      const styles = useStyles();

      return <div className={styles.counter}>{value}</div>;
    },
  },
  {
    id: 'edit',
    minWidth: 40,
    maxWidth: 40,
    disableSortBy: true,
    Cell: ({ row }: Cell) => {
      const styles = useStyles();

      const procedureUIStore = useProcedureUIStore();
      const userPermissionsUIStore = useUserPermissionsUIStore();
      const original: Record<string, any> = row.original;

      const onCellClick = () => {
        procedureUIStore.setEditProcedureId(original.id);
        procedureUIStore.toggleProcedureModalOpen(true);
      }
      var defaultProcedure=original.companyId=="00000000-0000-0000-0000-000000000000"&&!userPermissionsUIStore.isCompaniesAvailable;
    
        return (
          <button
            onClick={() => (userPermissionsUIStore.canUpdateProcedure||defaultProcedure) && onCellClick()}
            disabled={defaultProcedure||!userPermissionsUIStore.canUpdateProcedure}
            className={styles.button}
            title={messages['proceduresPage.editProcedure']}
          >
            <img src={EditIcon} />
          </button>
        )
    
    },
  },
]

export const ProceduresPage: React.FC = () => {
  const userPermissionsUIStore = useUserPermissionsUIStore();
  const proceduresGridUIStore = useProceduresUIStore();
  const procedureUIStore = useProcedureUIStore();

  const theme = useTheme();
  const styles = useStyles({ theme });
  const [filtersModalOpen, toggleFiltersModalOpen] = useState(false);

  const initialSortState = {
    sortBy: [{
      id: 'name',
      desc: false,
    }],
  };

  const columns: Column<object>[] = React.useMemo(() => columnsData, []);

  useEffect(() => {
    proceduresGridUIStore.togglePageActivity(true);

    proceduresGridUIStore.load();
    proceduresGridUIStore.loadStatistics();
    setTimeout(()=>{proceduresGridUIStore.togglePageActivity(true)},100);
    return () => {
      proceduresGridUIStore.togglePageActivity(false);
      proceduresGridUIStore.cleanUp();
    }
  }, []);

  const onFetchData = ({ pageIndex, sortBy }: FetchDataType) => {
    const params: IPageQueryStoreSnapshotIn = { page: pageIndex };

    if (sortBy && sortBy.length) {
      params.sortFieldName = sortBy[0].id;
      params.sortDirection = Number(sortBy[0].desc);
    }
    if(proceduresGridUIStore.isActivePage)
      proceduresGridUIStore.setParams(params);
  };

  const onSearchTextChanged = (searchText: string) => {
    if (searchText && searchText.length === 1) {
      return;
    }

    proceduresGridUIStore.setParams({
      page: 0,
      searchText,
    });
  };

  const onFiltersModalOpen = () => toggleFiltersModalOpen(true);
  const onFiltersModalClose = () => toggleFiltersModalOpen(false);

  const renderRowSubComponent = React.useCallback(({ row }: SubComponentProps) => {
    const data = row.originalSubRows[0];

    return (
      <div className={styles.body}>
        <div className={styles.descriptionWrapper}>
          <h5 className={styles.header}>{messages['proceduresPage.subRow.title']}</h5>
          <div className={styles.text}>
            {data.description || <NoInfo />}
          </div>
        </div>

        <div className={styles.devicesWrapper}>
          <h5 className={styles.header}>{messages['proceduresPage.subRow.devices']}</h5>
          <div className={styles.text}>
            {
              data.devices.length
                ? <ExpandableList list={data.devices} valueName='platform' />
                : <NoInfo />
            }
          </div>
        </div>

        <div className={styles.accessWrapper}>
          <h5 className={styles.header}>{messages['proceduresPage.subRow.access']}</h5>
          <div className={styles.text}>
            {
              data.access.length
                ? <ExpandableList list={data.access} />
                : <NoInfo />
            }
          </div>
        </div>

        <div className={styles.historyWrapper}>
          <h5 className={styles.header}>{messages['proceduresPage.subRow.history']}</h5>
          <div className={styles.text}>
            {
              data.history.length
                ? <ExpandableList list={data.history} />
                : <NoInfo />
            }
          </div>
        </div>
      </div>
    )
  }, []);

  const closeProcedureModal = () => {
    procedureUIStore.toggleProcedureModalOpen(false);
    procedureUIStore.cleanUpProcedureModal();
  };

  const onConfirmationDecline = () => {
    procedureUIStore.toggleDeleteConfirmationModalOpened(false);
    procedureUIStore.toggleProcedureModalOpen(false);
    procedureUIStore.cleanUpProcedureModal();
  };

  const onDeleteConfirm = async () => {
    const result = await procedureUIStore.deleteProcedure();

    if (result.success) {
      toast.success(
        <ToastMessage
          message={messages['proceduresPage.delete.success.toast']}
          type='success'
        />
      );

      proceduresGridUIStore.setParams({ page: 0 });
      proceduresGridUIStore.load();
      proceduresGridUIStore.loadStatistics();
    }

    procedureUIStore.toggleDeleteConfirmationModalOpened(false);
    procedureUIStore.cleanUpProcedureModal();
  }

  return (
    <>
      <ProceduresSummary />

      <div className={styles.tableActions}>
        <div className={styles.invite}>
          <h4 className={styles.h4}>{messages['proceduresPage.title']}</h4>

          {
            userPermissionsUIStore.canCreateProcedure && (
              <span style={{ display: 'inherit' }} title={messages['proceduresPage.addProcedure.title']} >
                <AddOutlinedIcon
                  style={{ fontSize: 24 }}
                  className={styles.addIcon}
                  onClick={() => procedureUIStore.toggleProcedureModalOpen(true)}
                />
              </span>
            )
          }
        </div>

        <div className={styles.filters}>
          <Observer>
            {() => (
              <>
                <SearchForm
                  initialValues={{
                    search: proceduresGridUIStore.requestParams.getStorageParams
                      ? proceduresGridUIStore.requestParams.getStorageParams.searchText
                      : '',
                  }}
                  onSearchTextChanged={onSearchTextChanged}
                  className={styles.searchWrapper}
                  disabled={proceduresGridUIStore.status?.isLoading}
                />
              </>
            )}
          </Observer>

          <span style={{ display: 'inherit' }} title={messages['proceduresPage.filter.title']}>
            <FilterListOutlinedIcon
              onClick={onFiltersModalOpen}
              className={styles.filterIcon}
            />
          </span>
        </div>
        <ProceduresFiltersModal isOpen={filtersModalOpen} onRequestClose={onFiltersModalClose} />
      </div>

      <ProceduresFiltersBar />
      <Observer>
        {() => {
          const data = proceduresGridUIStore.procedures.toJS();
          const preparedData = data.map(row => ({
            ...row,
            subRows: [{
              description: row.description,
              devices: row.devices,
              access: [...row.groups, ...row.users],
              history: row.history ? row.history.map(history => ({
                name: `${moment(history.date).format(DATE_MMM_DD_YYYY_HH_MM)}${history.description?" - "+history.description:""}`,
              })) : null,
            }],
          }));

          return (
            <>
              {
                procedureUIStore.procedureModalOpened && (
                  <ProcedureModal
                    isOpen={true}
                    onRequestClose={() => closeProcedureModal()}
                  />
                )
              }

              {
                procedureUIStore.deleteConfirmationModalOpened && (
                  <ConfirmationModal
                    isOpen={true}
                    message={messages['proceduresPage.delete.message']}
                    confirmText={messages['proceduresPage.delete.confirmText']}
                    onDecline={onConfirmationDecline}
                    onConfirm={() =>
                      procedureUIStore.procedureToEditId && onDeleteConfirm()
                    }
                  />
                )
              }

              {
                proceduresGridUIStore.isTableVisible && (
                  <Table
                    data={preparedData}
                    columns={columns}
                    fetchData={onFetchData}
                    isLoading={proceduresGridUIStore.status.isLoading}
                    renderRowSubComponent={renderRowSubComponent}
                    paginationStatus={{...proceduresGridUIStore.pagination}}
                    initialState={initialSortState}
                  />
                )
              }

              {
                proceduresGridUIStore.noFilteringResults && <NoResultsView entityName='procedures' />
              }

              {
                proceduresGridUIStore.noItemsInGroup && <NoProceduresInGroup />
              }
            </>
          );
        }}
      </Observer>
    </>
  )
}
