import React from 'react';
import { FieldProps } from 'formik';
import { useTheme } from 'react-jss';
import classNames from 'classnames';

import { BaseInputProps, APP_INNER_STYLE_VARIANT, APP_EXTERNAL_STYLE_VARIANT } from '../../BaseInputProps';
import { InputLabel } from '../InputLabel';
import { useStyles } from './DatePickerInput.styles';
import DatePicker from "react-datepicker";
import { Observable } from 'rxjs';
export type DatePickerInputProps = BaseInputProps & FieldProps;

export const DatePickerInput:React.FC<DatePickerInputProps> = props => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const {
    field: { name, value },
    form, meta,
    label, isRequired,
    disabled,
    className, readOnly, styleVariant = APP_INNER_STYLE_VARIANT,
    type, helperText, autoFocus,
  } = props;

  const inputProps = {
    autoFocus,
    className: classNames(
      className,
      styles.input,
      { [styles.textarea]: type === 'textarea' },
      { [styles.appExternal]: styleVariant === APP_EXTERNAL_STYLE_VARIANT },
      { [styles.withError]: meta.touched && meta.error }
    ),
    color: 'secondary',
    size: 'medium',
    disabled,
    type: type || 'small',
    value,
    onBlur: form.handleBlur,
    onChange: form.handleChange,
    readOnly,
  }
  const changeDate=(date:Date)=>{
    form.setFieldValue(name, date?.toString());
    inputProps.onChange(date?.toString());
  };
  return (
    <>
      {
        label && (
          <InputLabel
            label={label}
            isRequired={isRequired}
            styleVariant={styleVariant}
            className={classNames(
              { [styles.labelWithError]: meta.touched && meta.error }
            )}
          />
        )
      }

      <div className={styles.inputWrapper}>

        {
          //type === 'textarea'
          
            <DatePicker
              className={styles.input}
              selected={(inputProps.value && new Date(inputProps.value)) || null}
              onChange={changeDate}
              dateFormat="MMM dd, yyyy"
            />
          
        }

      </div>

      {
        helperText && <div className={styles.helperWrapper}>{helperText}</div>
      }
    </>
  );
}
