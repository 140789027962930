export const messages = {
  'companiesPage.pageTitle':
    'This part is reserved for Pacelab employees only.\n Please contact info@pacelabweavr.com for more info.',
  'companiesPage.placeholderAlt':
    'Collaboration management coming soon to WEAVR.\n Please contact info@pacelabweavr.com for more info.',
  'companyModal.error.required': (fieldLabel?: string) => `${fieldLabel} can't be blank`,
  'companyModal.submit.toast.success': (isEditMode: boolean) =>
    `${isEditMode ? 'The company info was successfully updated' : 'The company created successfully'}`,
  'companyModal.newCompany': 'New company',
  'companyModal.buttons.delete': 'Delete company',
  'companyModal.buttons.cancel': 'Cancel',
  'companyModal.buttons.submit': (isEditMode: boolean) => `${isEditMode ? 'Save' : 'Create'}`,
  'companyModal.validation.name': (name: string) => `${name} can only contain letters, digits, dashes and spaces`,
  'companyModal.name.label': 'Company name',
  'companyModal.description.label': 'Description',
  'companyModal.maxGroups.label': 'Max Groups',
  'companyModal.maxProcedures.label': 'Max Procedures',
  'companyModal.maxUsers.label': 'Max Users',
  'companyModal.maxLicensesCreator.label': 'Max Licenses Creator',
  'companyModal.maxLicensesPlayer.label': 'Max Licenses Player',
  'companyModal.expireAt.label': 'Expire At',
  'companyModal.dataIsolation.label': 'Isolate Data',
  'companyModal.dataIsolation.helperText': 'When Data Isolation is turned on, the company users can access only their groups',
  'companySwitchModal.password.label': 'Password',
  'companyModal.allowStandaloneBuild.label': 'Allow Standalone',
  'companyModal.multiadmin.label': 'Enabled admins',
  'companiesPage.noInfo': 'No information',
  'companiesPage.table.name.header': 'Company Name',
  'companiesPage.table.description.header': 'Description',
  'companiesPage.table.dataIsolation.header': 'Data Isolation',  
  'companiesPage.table.allowStandaloneBuild.header': 'Allow Standalone Build',  
  'companiesPage.table.allpow.header': 'Data Isolation',  
  'companiesPage.table.expireAt.header': 'Expire At',
  'companiesPage.table.createdAt.header': 'Created At',
  'companiesPage.table.maxGroups.header': 'Groups',
  'companiesPage.table.maxProcedures.header': 'Procedures',
  'companiesPage.table.maxUsers.header': 'Users',
  'companiesPage.table.maxLicensesCreator.header': 'Creator Licenses',
  'companiesPage.table.maxLicensesPlayer.header': 'Player Licenses',
  'companiesPage.table.actions.header': 'Actions',
  'companiesPage.table.editCompany': 'Edit company',
  'companiesPage.table.enterCompany': 'Enter company',
  'companiesPage.table.goToAnalytics': 'Company analytics',
  'companiesPage.removeCompany.toast.error': 'Failed to remove company',
  'companiesPage.removeCompany.toast.success': 'The company was successfully removed',
  'companiesPage.subRow.email': 'Email',
  'companiesPage.subRow.assignedGroups': 'Assigned groups',
  'companiesPage.subRow.assignedProcedures': 'Assigned procedures',
  'companiesPage.createCompany.title': 'Add company',
  'companiesPage.exitCompany.title': 'Exit Company',
  'companiesPage.switchCompany.label': 'Switch Company',
  'companiesPage.filter.title': 'Filter',
  'companiesPage.title': 'Companies',
  'companiesPage.delete.message': 'Are you sure you want to delete this company?',
  'companiesPage.delete.confirmText': 'Delete company',
  'companiesPage.noCompaniesInGroup': 'There are no companys in this group',
  'companiesPage.details.title': 'Options',
  'companiesPage.details.limitations': 'Max Limitation Counters'
}
