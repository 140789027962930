import { HttpStatusCode } from '@ace/core';

// export const BASE_URL = 'http://testing-ct-1936349679.eu-south-1.elb.amazonaws.com';
// export const BASE_URL = 'https://weavr-backend.txtgroup.com';
// export const BASE_URL = 'https://app-weavrmanager-dev-001.azurewebsites.net';
// export const BASE_URL = 'http://staging-c1-1945748706.eu-central-1.elb.amazonaws.com';

//export const BASE_URL = 'http://tv-ctv-1609552282.eu-central-1.elb.amazonaws.com';
//export const WEB_BASE_URL = 'http://tvweavrui.s3-website.eu-central-1.amazonaws.com';

export const BASE_URL = 'https://weavr-trial-backend.txtgroup.com';
export const WEB_BASE_URL = 'https://weavr-trial.txtgroup.com';
export const RECAPTCHA_KEY='6Lffn4YgAAAAAOcR8zweLlN7-_SP5XqlNL9QItra';

//export const BASE_URL = 'http://localhost:8001';  
//export const WEB_BASE_URL = 'http://localhost:4444';
//export const RECAPTCHA_KEY='6LcKB4sgAAAAACsVt7r_Yb-RjFG6YHg23ZJ3jvd3';

export const CLIENT_ID = 'ro.weavrManagerUI';
export const SECRET = 'secret';
export const SCOPE = 'offline_access openid profile identity analytic collaboration content manageruiagg server';
export const TOKEN_TYPE = 'Bearer';
export const GRANT_TYPE = 'refresh_token';

export const DEFAULT_API_ERROR_MESSAGES: Record<string, any> = {
  [HttpStatusCode.UNAUTHORIZED]: 'You are not authorized to perform this action',
  [HttpStatusCode.FORBIDDEN]: 'You are forbidden to perform this action',
  [HttpStatusCode.NOT_FOUND]: 'The resource was not found',
  [HttpStatusCode.REQUEST_TIMEOUT]: 'The request timed out',
  [HttpStatusCode.GATEWAY_TIMEOUT]: 'The request timed out',
  [HttpStatusCode.BAD_REQUEST]: 'Bad request',
  [HttpStatusCode.INTERNAL_SERVER_ERROR]: 'Internal server error',
}

