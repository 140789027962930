import React, { useEffect } from 'react';
import { useTheme } from 'react-jss';
import _ from 'lodash';

import { useStyles } from './DownloadPage.styles';
import { useDownloadablesUIStore } from '@core/useStores';
import { DownloadsSummary } from 'Download/components';
import { messages } from 'Download/download.messages';
import { Observer } from 'mobx-react';
import { SearchForm } from '@shared/components/SearchForm';
import { NoResultsView } from '@shared/components/NoResultsView';
import { DownloadGroup } from 'Download/components/DownloadGroup/DownloadGroup';




export const DownloadPage: React.FC = () => {
  const downloadablesUIStore = useDownloadablesUIStore();
  const theme = useTheme();
  const styles = useStyles({ theme });


  const onSearchTextChanged = (searchText: string) => {
    downloadablesUIStore.changeSearchText(searchText);
  };
  

  return (
    <>
      <DownloadsSummary/>

      <div className={styles.tableActions}>
        <div className={styles.invite}>
          <h4 className={styles.h4}>{messages['downloadPage.title']}</h4>
        </div>

        <div className={styles.filters}>
          <Observer>
            {() => (
                <SearchForm
                  initialValues={{
                    search: downloadablesUIStore.searchText
                      ? downloadablesUIStore.searchText
                      : '',
                  }}
                  onSearchTextChanged={onSearchTextChanged}
                  className={styles.searchWrapper}
                  disabled={downloadablesUIStore.isLoading}
                />
            )}
          </Observer>
        </div>
      </div>
      <Observer>
        {() => {
          
          return (
            <div className={styles.downloadableContainer}>
              {
                 downloadablesUIStore.downloadableGroups&&downloadablesUIStore.downloadableGroups.length>0&&
                  downloadablesUIStore.downloadableGroups.map(el=>
                    (<DownloadGroup name={el.name} description={el.description} downloadables={
                        el.downloadables.map(d=>{
                          var last=d.downloadableVersionFiles[0];
                          return {
                            name:d.name,
                            description:d.description,
                            idLast:last?.id,
                            versionLast:last?.version,
                            platformLast:last?.platform,
                            releaseNotesLast:last?.releaseNotes,
                            fileNameLast:last?.fileName,
                            dateLast:last?.createdAt,
                            olderDownloadableVersionFile:d.downloadableVersionFiles.slice(1).map(dvf=>{
                              return {
                                id:dvf.id,
                                version:dvf.version,
                                releaseNotes: dvf.releaseNotes,
                                platform: dvf.platform,
                                fileName: dvf.fileName,
                                date:dvf.createdAt
                              }
                            })
                        }
                      }
                      )
                } />))
              }
              

              {
                (!downloadablesUIStore.downloadableGroups||downloadablesUIStore.downloadableGroups.length==0) && <NoResultsView entityName='downloads' />
              }
            </div>
          );
        }}
      </Observer>
    </>
  )
}
