export const messages = {
  'downloadsSummary.total.title': 'Available',
  'downloadsSummary.total.text': (count: number | string) => `${count} ${count == 1 ? 'Download' : 'Downloads'}`,
  'downloadsSummary.title':'All Downloads',

  'downloadPage.noInfo':'No information',
  'downloadPage.title':'Download',

  'downloadPage.table.header.groupName':'Type',
  'downloadPage.table.header.name':'Name',
  'downloadPage.table.header.description':'Description',
  'downloadPage.table.header.version':'Version',
  'downloadPage.table.header.releaseNotes':'Description',
  'downloadPage.table.header.date':'Date',
  

  'downloadPage.subRow.description': 'Description',
  'downloadPage.subRow.releaseNotes': 'Release Notes',
  'downloadPage.subRow.olderVersions': 'Previous Versions',
  'downloadPage.table.header.platform': 'Platform',
  'downloadPage.download': 'Download'
}
