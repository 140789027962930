import React, { FC, useEffect } from 'react';
import { Observer } from 'mobx-react';
import { useTheme } from 'react-jss';
import { Form, Formik, FormikProps } from 'formik';
import { getEnv } from 'mobx-state-tree';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Subject } from 'rxjs';
import _ from 'lodash';

import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';
import { FormikSelectDropdown } from '@shared/components/forms/formikWrappers/FormikSelectDropdown';
import { SelectOption } from '@shared/components/SelectDropdown/Option';
import { convertOptionsToStrings, getOptionsByStrings, getOptionByString } from '@shared/helpers/form';
import { validationSchema } from './validationSchema';
import { valuesSchema } from './valuesSchema';
import { ProcedureFormValues, FormValues } from './FormValues';

import {
  useProcedureUIStore,
  useProceduresUIStore, useUserPermissionsUIStore,
} from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';
import { ToastMessage } from '@shared/components/Toast';
import { FormError } from '@shared/components/FormError';

import { useStyles } from '../../ProcedureModal.styles';
import { messages } from 'Procedures/procedures.messages';

export const COMMON_SELECT_PROPS = {
  isSearchable: true,
  hideSelectedOptions: false,
  isClearable: false,
  isMulti: true,
  closeMenuOnSelect: false,
  components: { Option: SelectOption },
};

const DEFAULT_OWNERSHIP = 'Private';

export type ProcedureDetailsFormProps = {
  className?: string,
  submitSubject: Subject<{}>,
};

export const ProcedureDetailsForm: FC<ProcedureDetailsFormProps> = ({
  className, submitSubject,
}) => {
  let submitForm: () => void;

  const theme = useTheme();
  const styles = useStyles(theme);

  const proceduresGridUIStore = useProceduresUIStore();
  const procedureUIStore = useProcedureUIStore();
  const userPermissionsUIStore = useUserPermissionsUIStore();

  const { filtersOptions } = getEnv<IStoresEnv>(proceduresGridUIStore);

  const groupsOptions = filtersOptions.getGroupsOptions;
  const usersOptions = filtersOptions.getUsersOptions;
  const ownershipsOptions = proceduresGridUIStore.proceduresFilters.getOwnershipsOptions;

  const onSubmit = async (values: ProcedureFormValues) => {
    const { name, description, groups, users, ownership } = values;
    const deviceIds = _.compact(
      procedureUIStore.devices.map(deviceUI => deviceUI?._device?.deviceId)
    );

    const computedValues = {
      name,
      description,
      deviceIds,
      groupIds: groups ? convertOptionsToStrings(groups) : [],
      userIds: users ? convertOptionsToStrings(users) : [],
      ownership: ownership ? ownership.value : '',
      token: procedureUIStore.processToken,
    }

    procedureUIStore.toggleLoading(true);

    const result = procedureUIStore.procedureToEditId
      ? await procedureUIStore.editProcedure({
        id: procedureUIStore.procedureToEditId,
        ...computedValues,
      })
      : await procedureUIStore.createProcedure(computedValues);

    procedureUIStore.toggleLoading(false);

    if (result.success) {
      proceduresGridUIStore.setParams({ page: 0 });
      proceduresGridUIStore.load();
      proceduresGridUIStore.loadStatistics();

      procedureUIStore.toggleProcedureModalOpen(false);
      procedureUIStore.cleanUpProcedureModal();

      toast.success(
        <ToastMessage
          type='success'
          message={
            procedureUIStore.procedureToEditId
              ? messages['procedureDetailsForm.update.success']
              : messages['procedureDetailsForm.create.success']
          }
        />
      );
    }else{
      procedureUIStore.toggleProcedureModalOpen(false);
      procedureUIStore.cleanUpProcedureModal();
      toast.error(
        <ToastMessage
          message={result.error?.body?.messages[0]}
          type='error'
        />
      );
    }
  };

  const bindSubmitForm = (submit: () => void) => submitForm = submit;

  useEffect(() => {
    submitSubject.subscribe(() => submitForm && submitForm());

    return () => submitSubject.unsubscribe();
  }, [submitSubject]);

  const existingInfo = procedureUIStore.procedureGeneralInfo;
  const initialValues = existingInfo ? {
    name: existingInfo?.name || '',
    description: existingInfo?.description || '',
    users: existingInfo?.userIds ? getOptionsByStrings(existingInfo?.userIds, usersOptions,existingInfo?.users) : [],
    groups: existingInfo?.groupIds ? getOptionsByStrings(existingInfo?.groupIds, groupsOptions, existingInfo?.groups) : [],
    ownership: getOptionByString(existingInfo?.ownership || DEFAULT_OWNERSHIP, ownershipsOptions),
  } : new FormValues();

  return (
    <div className={classNames(className)}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
      >
        {(form: FormikProps<ProcedureFormValues>) => {
          bindSubmitForm(form.submitForm);

          const storeErrors = procedureUIStore.errors;
          const formErrors: Record<string, any> = form.errors;
          const errors = Object.keys(formErrors).length
            ? Object.keys(formErrors).map((key: string) =>
              formErrors[key].value ? formErrors[key].value : formErrors[key]
            )
            : storeErrors;

          return (
            <Form autoComplete='off' noValidate>
              <Observer>
                {() => (
                  <>
                    {errors?.length && (
                      <div className={styles.errors}>
                        {errors.map((text: string) => (
                          <FormError className={styles.error} key={text} text={text} />
                        ))}
                      </div>
                    )}
                  </>
                )}
              </Observer>

              <div className={styles.field}>
                <FormikTextInput
                  schema={valuesSchema.name}
                  cleanable
                />
              </div>

              <div className={styles.field}>
                <FormikTextInput
                  schema={valuesSchema.description}
                  cleanable
                />
              </div>

              <div className={styles.field}>
                <FormikSelectDropdown
                  schema={valuesSchema.groups}
                  className={styles.dropdown}
                  options={groupsOptions}
                  disabled={!userPermissionsUIStore.canChangeAssignedGroups}
                  {...COMMON_SELECT_PROPS}
                />
              </div>

              <div className={styles.field}>
                <FormikSelectDropdown
                  schema={valuesSchema.users}
                  className={styles.dropdown}
                  options={usersOptions}
                  disabled={!userPermissionsUIStore.canChangeAssignedUsers}
                  {...COMMON_SELECT_PROPS}
                />
              </div>

              <div className={styles.field}>
                <FormikSelectDropdown
                  schema={valuesSchema.ownership}
                  className={styles.dropdown}
                  options={ownershipsOptions}
                  disabled={!userPermissionsUIStore.canChangeOwnership}
                  {...COMMON_SELECT_PROPS}
                  isMulti={false}
                  isSearchable={false}
                  closeMenuOnSelect={true}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
