

import { IApiStore } from '@core/api/apiStore';
import { EndpointCallParams } from '@core/api/endpointCallParams';


export const performCreateLicense = async (api: IApiStore, params: EndpointCallParams<string>) => {
  const { payload, ...rest } = params;
  const req = api
    .post('identity-app/api/v1/License/Create', {
      ...rest,
      payload,
    })

  return req;
}
