import {
  Instance, SnapshotIn, types, flow, getEnv,
  applySnapshot, getSnapshot, SnapshotOut
} from 'mobx-state-tree';

import {
  GetLicensesParams,
  performCreateLicense,
  performToggleLicense,
  performGetLicenses,
  performGetLicensesStatistics,
} from '../service';
import { LicenseStore } from './License.store';
import { LicensesStatisticsStore } from './LicensesStatistics.store';
import { IStoresEnv } from '@core/storesEnv';
import { paginationStatusInitialState, PaginationStatusStore } from 'Users/stores/PaginationStatus.store';


export const LicensesDataStoreInferred = types
  .model('LicenseDataStore', {
    licenses: types.array(LicenseStore),
    paginationStatus: types.optional(PaginationStatusStore, {}),
    selectedId: types.maybeNull(types.string),
    selectedName: types.maybeNull(types.string),
    selectedDisabled: types.maybeNull(types.boolean),
    statistics: types.optional(LicensesStatisticsStore, {}),
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    const load = flow(function* (payload: GetLicensesParams) {
      const result = yield performGetLicenses(api, {
        payload,
        errorHandlers: { DEFAULT: '' },
      });
      if (result.success) {
        self.licenses = result.data.entities?result.data.entities:[];
        self.licenses.forEach(l=> l.usedIn=l.isCreator?l.isPlayer?'Both':'Creator':l.isPlayer?'Player':'Offline');
        self.paginationStatus = result.data.meta?result.data.meta:paginationStatusInitialState;
      }
    });
    const createLicense = flow(function* () {
      const result = yield performCreateLicense(api, {
        payload:{UnityId:self.selectedId,Name:self.selectedName},
        errorHandlers: { DEFAULT: '' },
      });
      return result;
    });
    const toggleLicense = flow(function* () {
      const result = yield performToggleLicense(api, {
        payload:{UnityId:self.selectedId,IsDisabled:self.selectedDisabled},
        errorHandlers: { DEFAULT: '' },
      });
      return result;
    });

    const loadStatistics = flow(function* () {
      const result = yield performGetLicensesStatistics(
        api,
        {
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.statistics = result.data;
    });

    return {
      load,
      createLicense,
      toggleLicense,
      loadStatistics
    }
  });

type LicensesDataStoreFactoryType = typeof LicensesDataStoreInferred;
interface ILicensesDataStoreFactory extends LicensesDataStoreFactoryType {}
export const LicensesDataStore: ILicensesDataStoreFactory = LicensesDataStoreInferred;
export interface ILicensesDataStore extends Instance<ILicensesDataStoreFactory> {}
export interface ILicensesDataStoreSnapshotIn extends SnapshotIn<ILicensesDataStore> {}
export interface ILicensesDataStoreSnaphotOut extends SnapshotOut<ILicensesDataStore> {}
