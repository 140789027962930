import {
  Instance, SnapshotIn, types, flow, getEnv,
  applySnapshot, getSnapshot, SnapshotOut
} from 'mobx-state-tree';

import {
  GetDownloadablesParams,
  performDownloadDownloadables,
  performGetDownloadables,
  
} from '../service';
import { IStoresEnv } from '@core/storesEnv';
import { paginationStatusInitialState, PaginationStatusStore } from 'Users/stores/PaginationStatus.store';
import { DownloadableGroupStore } from './DownloadableGroup.store';
import { toast, ToastId } from 'react-toastify';
import { ProgressEvent } from 'superagent';


export const DownloadablesDataStoreInferred = types
  .model('DownloadableDataStore', {
    //downloadables: types.map(types.string,types.array(DownloadableStore)),
    downloadableGroups: types.array(DownloadableGroupStore),
    paginationStatus: types.optional(PaginationStatusStore, {}),
    selectedId: types.maybeNull(types.string),
    selectedFileName: types.optional(types.string,'empty'),
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    const load = flow(function* (payload: GetDownloadablesParams) {
      const result = yield performGetDownloadables(api, {
        payload,
        errorHandlers: { DEFAULT: '' },
      });
      if (result.success) {
        self.downloadableGroups = result.data.entities?result.data.entities:[];
        self.paginationStatus = result.data.meta?result.data.meta:paginationStatusInitialState;
      }
    });
    const download = flow(function* (fileName:string,toastId:ToastId,progressCallback?:(e:ProgressEvent)=>void) {
      const result = yield performDownloadDownloadables(api, {
        payload:{id:self.selectedId},
        errorHandlers: { DEFAULT: '' },
      },
      progressCallback);
      if (!(result.data instanceof Blob)) return;
      toast.dismiss(toastId);
      const blob = new Blob([result.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    });

    return {
      load,
      download
    }
  });

type DownloadablesDataStoreFactoryType = typeof DownloadablesDataStoreInferred;
interface IDownloadablesDataStoreFactory extends DownloadablesDataStoreFactoryType {}
export const DownloadablesDataStore: IDownloadablesDataStoreFactory = DownloadablesDataStoreInferred;
export interface IDownloadablesDataStore extends Instance<IDownloadablesDataStoreFactory> {}
export interface IDownloadablesDataStoreSnapshotIn extends SnapshotIn<IDownloadablesDataStore> {}
export interface IDownloadablesDataStoreSnaphotOut extends SnapshotOut<IDownloadablesDataStore> {}
