export const messages = {
  'licensesSummary.total.title': 'Available',
  'licensesSummary.total.text': (count: number | string) => `${count} ${count == 1 ? 'License' : 'Licenses'}`,
  'licensesSummary.player.title': 'Players',
  'licensesSummary.player.text': (count: number | string) => `${count} ${count == 1 ? 'License' : 'Licenses'}`,
  'licensesSummary.creator.title': 'Creators',
  'licensesSummary.creator.text': (count: number | string) => `${count} ${count == 1 ? 'License' : 'Licenses'}`,
  'licensesSummary.title':'All Licenses',

  'licensePage.noInfo':'No information',
  'licensePage.title':'Licenses',

  'licensePage.download': 'License download',
  'licensePage.switch': 'Enable/disable the license',
  'licensePage.subRow.unityId': 'Unity Id',
  'licensePage.subRow.key': 'License Key',
  'licensePage.subRow.allUser': 'All logged in user',
  'licensePage.table.header.name':'Tag',
  'licensePage.table.header.unityId':'Unity id',
  'licensePage.table.header.key':'License Key',
  'licensePage.table.header.lastUser': 'Last user',
  'licensePage.table.header.lastLogin': 'Last login',
  'licensePage.table.header.allUser': 'All logged in user',
  'licensePage.table.header.expireDate':'Expiration Date',
  'licensePage.table.header.usedIn':'Used In',
  'licenseModal.submit.toast.success':'New license key created',
  'licenseModal.newLicense':'New license',

  'licenseModal.buttons.cancel': 'Cancel',
  'licenseModal.buttons.submit': 'Create',
  
  'licenseModal.name.label':'Tag name',
  'licenseModal.unityId.label':'Unity Id',

  'licenseModal.error.required': (fieldLabel?: string) => `${fieldLabel} can't be blank`,
  'licensePage.createLicense.title': 'Create new user',
}
