import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { createUseStyles, useTheme } from 'react-jss';
import { ThemeProps } from '@styles/theme';

import { SummaryPanel } from '@shared/components/SummaryPanel';
import {
  useDownloadablesUIStore
} from '@core/useStores';

import ProceduresIcon from '@assets/procedures.svg';
import { messages } from 'Download/download.messages';

const useStyles = createUseStyles((theme: ThemeProps) => ({
  panelWrapper: {
    marginTop: -80,
    marginBottom: theme.spacing(4),
  },
}));

export const DownloadsSummary: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  
  const downloadablesUIStore = useDownloadablesUIStore();
  
  const renderValue = (value: number | null) => value === null ? '' : value;

  return (
    <Observer>
      {() => {
        const data = downloadablesUIStore.statistics;
        const preparedData = [
          {
            id: 0,
            title: messages['downloadsSummary.total.title'],
            text: messages['downloadsSummary.total.text'](renderValue(data)),
            icon: ProceduresIcon,
          }
        ];

        
        const title = messages['downloadsSummary.title'];

        return (
          <div className={styles.panelWrapper}>
            <SummaryPanel
              title={title}
              items={preparedData}
            />
          </div>
        )
      }}
    </Observer>
  )
}
