import { pick } from 'lodash';

export type RegisterFormValues = {
  companyName: string,
  email: string,
  name: string,
  surname: string,
};

export class FormValues {
  companyName = '';
  email = '';
  name = '';
  surname = '';
  companyDescription = '';
  department ='';
  
  constructor(registerData?: RegisterFormValues | null) {
    if (registerData) {
      Object.assign(this, pick(registerData, ...Object.keys(this)));
    }
  }
}
