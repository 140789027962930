

import { IApiStore } from '@core/api/apiStore';
import { EndpointCallParams } from '@core/api/endpointCallParams';

export type RegisterTrialParams = {
  companyName: string;
  companyDescription: string;
  firstName:string;
  lastName:string;
  department:string;
  email:string;
  captcha:string;
};

export const performRegisterTrial = async (api: IApiStore, params: EndpointCallParams<RegisterTrialParams>) => {
  const { payload, ...rest } = params;
  const req = api
    .post('identity-app/api/v1/Account/Register', {
      ...rest,
      payload: {
        ...payload,
      },
    })

  return req;
}
