import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  halfWidth: {},
  modal: {
    maxHeight: 'calc(100vh - 50px)',
    overflow: 'auto'
  },
  form: {
    width: 476,
  },
  field: {
    marginBottom: theme.spacing(6),
    '&$halfWidth': {
      width: 'calc(50% - 12px)',
    },
  },
  fieldWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  dropdown: {
    margin: 0,
  },

  end: {
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  
  error: {
    backgroundColor: theme.colors.red,
    marginBottom: theme.spacing(2),
  },
}));
