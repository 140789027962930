import { Instance, SnapshotIn, types, flow, getSnapshot, onSnapshot } from 'mobx-state-tree';

import { convertHhMmSsToSeconds } from '@shared/helpers/timeFormatters';
import { UserAnalyticStore } from './UserAnalytic.store';
import { UserAnalyticsPageQueryParams } from 'Analytics/domain/UserAnalyticsPageQueryParams';

const MAX_REGULAR_ATTEMPTS = 3; // used as a backup, BE should only return 3 last attempts

export const UserAnalyticUIStoreInferred = types
  .model('UserAnalyticUIStoreInferred', {
    _analytics: types.optional(UserAnalyticStore, {}),
    isLoading: types.optional(types.boolean, false), // TODO: consider using volatile for such cases
    _isStepsLoading: types.optional(types.boolean, false),
    _isStatisticsLoading: types.optional(types.boolean, false),
    _selectedProcedureName: types.maybeNull(types.string),
    _procedureStepsError: types.maybeNull(types.string),
  })
  .views(self => ({
    get requestParams() { return self._analytics.requestParams; },
    get statistics() { return getSnapshot(self._analytics.statistics); },
    get procedureSteps() {
      const steps = self._analytics.steps;
      const formattedSteps = steps?.map(step => {
        const stepData: Record<string, any> = {
          name: step.stepName,
          allAverage: {
            time: convertHhMmSsToSeconds(step.averageExecutionsTime),
          },
          usrBest: {
            date: new Date(step.bestTime.date),
            time: convertHhMmSsToSeconds(step.bestTime.executionTime),
          },
        };

        step.stepExecutions.slice(0, MAX_REGULAR_ATTEMPTS).map((st, i) => {
          stepData[`usr${i+1}`] = {
            time: convertHhMmSsToSeconds(st.executionTime),
            date: new Date(st.date),
          }
        })

        return stepData;
      });

      return formattedSteps;
    },
    get isAnalyticsLoading() { return self.isLoading },
    get isStatisticsLoading() { return self._isStatisticsLoading },
    get isStepsLoading() { return self._isStepsLoading },
    get allUsersAverage() { return convertHhMmSsToSeconds(self._analytics.allUsersAverage) },
    get bestTime() { return convertHhMmSsToSeconds(self._analytics.bestTime) },
    get executedProcedures() {
      return self._analytics.executedProcedures
        ? self._analytics.executedProcedures.map(procedure => ({
          name: procedure.name,
          value: procedure.executionsCount,
          id: procedure.id,
        })) : []
    },
    get notExecutedProcedures() {
      return self._analytics.notExecutedProcedures
        ? self._analytics.notExecutedProcedures.map(procedure => ({
          name: procedure.name,
          value: procedure.name,
          id: procedure.id,
        }))
        : []
    },
    get analyticsFilters() { return self._analytics.analyticsFilterOptions; },
    get selectedProcedureName() { return self._selectedProcedureName; },
    get procedureStepsError() { return self._procedureStepsError; },
  }))
  .actions(self => {
    const loadStatistics = flow(function * (payload) {
      self._isStatisticsLoading = true;
      yield self._analytics.loadUserAnalytic(payload);
      self._isStatisticsLoading = false;
    });

    const loadUserProceduresExecutions = flow(function * (payload) {
      self.isLoading = true;
      yield self._analytics.loadUserProceduresExecutions(payload);
      self.isLoading = false;
    });

    const loadUserProcedureSteps = flow(function * (payload) {
      self._isStepsLoading = true;
      self._procedureStepsError = null;
      const result = yield self._analytics.loadUserProcedureSteps(payload);

      self._isStepsLoading = false;

      if (!result.success) {
        // TODO Ask BE make this error consistent( in most cases we use errors)
        const { body } = result.error || {};

        // TODO Ask BE make this error consistent( in most cases we use errors, not messages)
        self._procedureStepsError = body?.messages[0];
      }

      return result;
    });

    const setProcedureName = (name: string) => {
      self._selectedProcedureName = name;
    }

    const unselectProcedureName = () => {
      self._selectedProcedureName = null;
    }

    const setParams = (params: UserAnalyticsPageQueryParams) => {
      self.requestParams.setParams(params);
    }

    const resetParams = () => self.requestParams.resetParams();

    return {
      loadStatistics,
      loadUserProceduresExecutions,
      loadUserProcedureSteps,
      setProcedureName,
      unselectProcedureName,
      setParams,
      resetParams,
      afterCreate: () => {
        onSnapshot(self.requestParams, () => {
          self._analytics.resetSteps();
          const { procedureVersionId, procedureVersionPlatformId, userId, procedureId, executionMode } = self.requestParams.getParams;

          if (procedureVersionId && procedureVersionPlatformId && userId && procedureId && executionMode) {
            let params =Object. assign({},self.requestParams.getParams);
            params.endDate= new Date(new Date(params.endDate).setDate(new Date(params.endDate).getDate()+1)).toISOString();
            
            loadUserProcedureSteps(params)
          }
        });
      },
    }
  })

type UserAnalyticUIStoreFactoryType = typeof UserAnalyticUIStoreInferred;
interface IUserAnalyticUIStoreFactory extends UserAnalyticUIStoreFactoryType {}
export const UserAnalyticUIStore: IUserAnalyticUIStoreFactory = UserAnalyticUIStoreInferred;
export interface IUserAnalyticUIStore extends Instance<IUserAnalyticUIStoreFactory> {}
export interface IUserAnalyticUIStoreSnapshotIn extends SnapshotIn<IUserAnalyticUIStore> {}
