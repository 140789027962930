export const messages = {
  'server.username.error': 'Invalid username.',
  'server.email.cantFind.error':
    'Sorry, we couldn’t find an account with that email. Please contact your administrator.',
  'server.password.error': 'Invalid password.',
  'server.trial.error': 'loginForm.error.trialExpired',
  'server.company.error': 'loginForm.error.companyRemoved',
  'server.password.invalid.error': 'Password incorrect. If forgotten, reset it now.',
  'server.token.error': 'Invalid token.',
  'server.token.invalid.error': 'The link has expired. Please contact your instructor.',
  'error.default': 'Sorry, something went wrong',
  'forgotPasswordForm.success': (email: string) => `A password reset link has been sent you to ${email}`,
  'forgotPasswordFormControls.title': 'Forgot your password?',
  'forgotPasswordFormControls.subtitle': 'We’ll email you a password reset link.',
  'forgotPasswordFormControls.confirm': 'Confirm',
  'forgotPasswordForm.validation.email': 'Email must contain \'@\', \'.\' and must not contain space',
  'forgotPasswordForm.validation.required': (fieldLabel?: string) => `${fieldLabel} is required.`,
  'loginForm.error.default':
    'Sorry, that email or password isn’t correct. Please try again or contact your administrator.',
  'loginForm.error.trialExpired':'Trial version expired, please contact us in order to activate the unlimited license.',
  'loginForm.error.companyRemoved':'Your company has been disabled, please contact us in order to reactivate it.',
  'loginForm.forgotPassword.title': 'Forgot your password?',
  'loginForm.login': 'Login',
  'loginForm.register': 'Register',
  'loginForm.email.label': 'Email',
  'loginForm.password.label': 'Password',

  
  'setPasswordForm.error.default': 'Please try again or contact your administrator.',
  'setPasswordForm.success':
    (inviteMode: boolean) => `Your password has been successfully ${inviteMode ? 'set' : 'changed'}.`,
  'setPasswordForm.helperText': `
    Password must have at least 8 symbols, and
    include at least one lowercase letter, one uppercase letter, and a number.
  `,
  'setPasswordForm.formTitle': (inviteMode: boolean) => `${inviteMode ? 'Set' : 'Reset'} your password`,
  'setPasswordForm.actionTitle': (inviteMode: boolean) => `${inviteMode ? 'Set' : 'Reset'} password`,
  'setPasswordForm.error.required': (fieldLabel?: string) => `${fieldLabel} is required.`,
  'setPasswordForm.error.notSame': 'Passwords are not equal.',
  'setPasswordForm.error.min': (number: number) => `Password must be at least ${number} characters.`,
  'setPasswordForm.error.otherRequirements':
    'Password must include at least one lowercase letter, one uppercase letter, and a number.',
  'setPasswordForm.error.notContainsUsername': 'Password cannot contain username.',
  'setPasswordForm.label.password': 'New password',
  'setPasswordForm.label.confirmPassword': 'Confirm password',
  'confirmPasswordForm.label.password': 'Password',
  'confirmPasswordForm.label.title': (action: string) => `Confirm ${action}`,
  'confirmPasswordForm.button.submit': 'Confirm',
  'confirmPasswordForm.button.cancel': 'Cancel',
  'confirmPasswordForm.action.success': (action: string) => `${action} performed successfully`,
  'confirmPasswordForm.action.fail': (action: string) => `${action} failed`,
}
