import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  halfWidth: {
  },
  errorWrapper: {
    marginBottom: theme.spacing(3),
    color: '#fff',
  },
  viewWrapper: {
    width: 568,
    overflow:'auto',
    height:'100%',
    paddingLeft:theme.spacing(5),
    paddingRight:theme.spacing(5),
  },
  h2: {
    fontFamily: theme.font.secondary,
    fontSize: 24,
    lineHeight: 1.33,
    color: theme.colors.black,
    fontWeight: 'normal',
    position: 'sticky',
    padding:theme.spacing(5),
    paddingBottom:theme.spacing(1),
    top: 0,
    backgroundColor: theme.colors.white,
    zIndex: 1,
  },
  formWrapper: {
    margin: [0, 'auto'],
    width: 500,
    

  },
  fieldsWrapper:{
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  fieldWrapper: {
    marginBottom: theme.spacing(4),
    width: 'calc(100%)',
    '&$halfWidth': {
      width: 'calc(50% - 12px)',
    },
  },

  forgotPassWrapper: {
    textAlign: 'right',
    marginBottom: theme.spacing(8),
  },
  buttons: {
    display: 'flex',
    marginTop: 10,
    justifyContent: 'flex-end',
    paddingBottom:theme.spacing(5)
  },
  button: {
    marginRight: theme.spacing(5),
  },
  
  actionWrapper: {

  },
}));
