import React, { useState, useEffect } from 'react';
import { getEnv } from 'mobx-state-tree';
import { Observer } from 'mobx-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useTheme } from 'react-jss';
import { Cell, Column } from 'react-table';
import classNames from 'classnames';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

import { DATE_MMM_DD_YYYY } from '@shared/constants';
import { Avatar } from '@shared/components/Avatar';
import { Table } from '@shared/components/Table';
import { SearchForm } from '@shared/components/SearchForm';
import { UsersSummary } from '@shared/components/UsersSummary';
import { NoResultsView } from '@shared/components/NoResultsView';
import { ExpandableList } from '@shared/components/ExpandableList';
import ArrowRightIcon from '@assets/arrow-right.svg';
import EditIcon from '@assets/edit.svg';
import StatsIcon from '@assets/chart.svg';
import ResendInvitationIcon from '@assets/resend-invitation.svg';

import {
  useUsersGridUIStore,
  useUserUIStore,
  useUserPermissionsUIStore,
  useUserProfileUIStore,
} from '@core/useStores';
import { RoutePaths } from '@core/routes/RoutePaths';
import { IStoresEnv } from '@core/storesEnv';
import { useStyles } from './UsersPage.styles';
import { UsersFiltersBar } from '../UsersFiltersBar';
import { UsersFiltersModal } from '../UsersFiltersModal';
import { NoUsersInGroup } from '../NoUsersInGroup';
import { UserModal } from '../UserModal';
import { ResendInviteModal } from '../ResendInviteModal';
import { UserRole } from '../../domain/UserRole';
import { ResendInvitationInputData } from '../../domain/ResendInvitationInputData';
import { ConfirmationModal } from '@shared/components/ConfirmationModal';
import { ToastMessage } from '@shared/components/Toast';

import { messages } from 'Users/users.messages';
import { IPageQueryStoreSnapshotIn } from 'Users/stores';

type SubComponentProps = {
  row: {
    originalSubRows: Array<{
      email: string,
      groups: {id: string, name: string}[],
      procedures: {id: string, name: string}[],
    }>,
  }
}

type FetchDataType = {
  pageIndex: number;
  sortBy: {
    id: string;
    desc: boolean;
  }[]
}

const EXPANDER_CELL_WIDTH = 24;

const NoInfo = () => {
  const styles = useStyles();

  return <div className={styles.noInfo}>{messages['usersPage.noInfo']}</div>
}

const columnsData = [
  {
    id: 'expander',
    minWidth: 40,
    maxWidth: 40,
    Header: () => (
      <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
    ),
    Cell: ({ row }: Cell) => {
      const { style, ...props } = row.getToggleRowExpandedProps();
      const styles = useStyles();

      return (
        <div
          {...props}
          style={{
            ...style,
            minWidth: EXPANDER_CELL_WIDTH,
            display: 'flex',
          }}
        >
          {row.canExpand && (
            <img
              src={ArrowRightIcon}
              className={classNames(styles.expandIcon, { [styles.expanded]: row.isExpanded })}
            />
          )}
        </div>
      )
    },
    disableSortBy: true,
  },
  {
    Header: messages['usersPage.table.fullName.header'],
    accessor: 'fullName',
    minWidth: 98,
    width: 17,
    Cell: ({ row, value }: Cell) => {
      const styles = useStyles();
      const { firstName, lastName, avatar } = row.original as {
        firstName: string, lastName: string, avatar: string,
      };

      return (
        <div className={styles.userWrapper}>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            image={avatar}
            className={styles.userAvatar}
          />
          <span className={styles.ellipsis}>{ value }</span>
        </div>
      );
    },
  },
  {
    Header: messages['usersPage.table.department.header'],
    accessor: 'department',
    minWidth: 86,
    width: 7,
    disableEllipsis: true,
    Cell: ({ value }: Cell) => <span title={value}>{value}</span>,
  },
  {
    Header: messages['usersPage.table.groups.header'],
    accessor: 'groups',
    minWidth: 100,
    width: 9,
    Cell: ({ value, row }: Cell) => {
      const { ...props } = row.getToggleRowExpandedProps();
      const styles = useStyles();
      const firstGroup = value.length && value[0];

      if (!firstGroup) {
        return <NoInfo />;
      }

      return (
        <>
          {firstGroup.name}
          {value.length > 1 && (
            <span {...props} className={styles.moreItemsPresent}>+{value.length - 1}</span>
          )}
        </>
      )
    },
  },
  {
    Header: messages['usersPage.table.roles.header'],
    accessor: 'roles',
    minWidth: 90,
    maxWidth: 110,
    width: 8,
    Cell: ({ value }: Cell) => {
      const styles = useStyles();

      return (
        value.length
          ? <span className={styles.capitalized}>{value[0].name.toLowerCase()}</span>
          : <NoInfo />
      );
    },
  },
  {
    Header: messages['usersPage.table.lastLoginAt.header'],
    accessor: 'lastLoginAt',
    minWidth: 76,
    width: 9,
    Cell: ({ value }: Cell) => value ? moment(value).format(DATE_MMM_DD_YYYY) : <NoInfo />,
  },
  {
    Header: messages['usersPage.table.status.header'],
    accessor: 'status',
    minWidth: 100,
    maxWidth: 100,
    Cell: ({ value }: Cell) => {
      const styles = useStyles();

      return (
        <div className={
          classNames(styles.userStatus, {[styles.notActive]: value?.name !== 'Active'})
        }>
          {value?.name}
        </div>
      )
    },
  },
  {
    id: 'edit',
    minWidth: 40,
    maxWidth: 40,
    disableSortBy: true,
    Cell: ({ row }: Cell) => {
      const styles = useStyles();

      const userUIStore = useUserUIStore();
      const userId = useUserProfileUIStore().userInfo.id;
      const userPermissionsUIStore = useUserPermissionsUIStore();

      const original: Record<string, any> = row.original;
      const userRoles = original.roles.map((role: UserRole) => role.name);

      const canEdit = original.id === userId || userPermissionsUIStore.canEditUser(userRoles);

      const onCellClick = () => {
        userUIStore.setEditUserId(original.id)
        userUIStore.toggleUserModalOpen(true);
      }

      return (
        <button
          onClick={() => canEdit && onCellClick()}
          disabled={!canEdit}
          className={styles.button}
          title={messages['usersPage.table.editUser']}
        >
          <img src={EditIcon} />
        </button>
      )
    },
  },
  {
    id: 'additionalActions',
    minWidth: 42,
    maxWidth: 42,
    disableSortBy: true,
    Cell: ({ row }: Cell) => {
      const styles = useStyles();

      const userUIStore = useUserUIStore();
      const userPermissionsUIStore = useUserPermissionsUIStore();
      const { navigator } = getEnv<IStoresEnv>(userUIStore);

      const original: Record<string, any> = row.original;
      const isInvited = original.status.name === 'Invited';

      const canResendInvite = userPermissionsUIStore.canCreateUser;

      const openResendInviteModal = () => {
        const inputData: ResendInvitationInputData = {
          id: original.id,
          email: original.email,
          fullName: original.fullName,
        };

        userUIStore.setResendInvitationInputData(inputData);
        userUIStore.toggleResendInvitationModalOpened(true);
      };

      const openUserAnalytic = () => {
        navigator.to(`${RoutePaths.analytics}/${original.id}`);
      };

      return (
        <button
          onClick={() => {
            if (canResendInvite && isInvited) {
              openResendInviteModal();
            } else {
              openUserAnalytic();
            }
          }}
          className={classNames(styles.button, styles.buttonInvite)}
          disabled={!canResendInvite}
        >
          {
            isInvited
              ? <img title={messages['usersPage.table.resendInvitation']} src={ResendInvitationIcon} />
              : <img title={messages['usersPage.table.goToAnalytics']} src={StatsIcon} />
          }
        </button>
      )
    },
  },
]

export const UsersPage: React.FC = () => {
  const [filtersModalOpen, toggleFiltersModalOpen] = useState(false);

  const userPermissionsUIStore = useUserPermissionsUIStore();
  const usersGridUIStore = useUsersGridUIStore();
  const userUIStore = useUserUIStore();

  const { filtersOptions } = getEnv<IStoresEnv>(usersGridUIStore);

  const theme = useTheme();
  const styles = useStyles({ theme });

  const initialSortState = {
    sortBy: [{
      id: 'fullName',
      desc: false,
    }],
  };

  const columns: Column<object>[] = React.useMemo(() => columnsData, []);

  useEffect(() => {
    

    usersGridUIStore.cleanUp();

    usersGridUIStore.load();
    usersGridUIStore.loadStatistics();
    
    filtersOptions.loadFilterOptions();
    setTimeout(()=>{usersGridUIStore.togglePageActivity(true)},100);
    return () => {
      usersGridUIStore.togglePageActivity(false);
      usersGridUIStore.cleanUp();
    }
  }, []);

  const onFetchData = ({ pageIndex, sortBy }: FetchDataType) => {
    const params: IPageQueryStoreSnapshotIn = { page: pageIndex };

    if (sortBy && sortBy.length) {
      params.sortFieldName = sortBy[0].id;
      params.sortDirection = Number(sortBy[0].desc);
    }
    if(usersGridUIStore.isActivePage)
      usersGridUIStore.setParams(params);
  };

  const onSearchTextChanged = (searchText: string) => {
    if (searchText && searchText.length === 1) {
      return;
    }

    usersGridUIStore.setParams({
      page: 0,
      searchText,
    });
  };

  const onInviteUserModalOpen = () => {
    userUIStore.clearEditUserId();
    userUIStore.toggleUserModalOpen(true);
  }

  const onDeleteUserDecline = () => {
    userUIStore.toggleDeleteConfirmationModalOpened(false);
    userUIStore.toggleUserModalOpen(false);
    userUIStore.clearEditUserId();
  }

  const onDeleteUserConfirm = async () => {
    const result = await userUIStore.deleteUser({ id: userUIStore.userToEditId });

    if (!result.success) {
      toast.error(
        <ToastMessage
          message={messages['usersPage.removeUser.toast.error']}
          type='error'
        />
      );
    } else {
      toast.success(
        <ToastMessage
          message={messages['usersPage.removeUser.toast.success']}
          type='success'
        />
      );

      usersGridUIStore.setParams({ page: 0 });
      usersGridUIStore.load();
      usersGridUIStore.loadStatistics();
      filtersOptions.loadFilterOptions();
    }

    userUIStore.clearEditUserId();
    userUIStore.toggleUserModalOpen(false);
    userUIStore.toggleDeleteConfirmationModalOpened(false);
  }

  const onInviteUserModalClose = () => {
    const params = usersGridUIStore.inviteUser.getInviteUserFields;

    usersGridUIStore.inviteUser.setInviteUserFields({ ...params, errors: null });
    userUIStore.clearEditUserId();
    userUIStore.toggleUserModalOpen(false);
  }

  const onResendInviteUserModalClose = () => {
    userUIStore.setResendInvitationInputData(null);
    userUIStore.toggleResendInvitationModalOpened(false);
  }

  const renderRowSubComponent = React.useCallback(({ row }: SubComponentProps) => {
    const data = row.originalSubRows[0];

    return (
      <div className={styles.body}>
        <div className={styles.emailWrapper}>
          <h5 className={styles.header}>{messages['usersPage.subRow.email']}</h5>
          <p className={styles.text}>{data.email}</p>
        </div>

        <div className={styles.groupsWrapper}>
          <h5 className={styles.header}>{messages['usersPage.subRow.assignedGroups']}</h5>
          <div className={styles.text}>
            {
              data.groups.length
                ? <ExpandableList list={data.groups} />
                : <NoInfo />
            }
          </div>
        </div>

        <div>
          <h5 className={styles.header}>{messages['usersPage.subRow.assignedProcedures']}</h5>
          <div className={styles.text}>
            {
              data.procedures.length
                ? <ExpandableList list={data.procedures} />
                : <NoInfo />
            }
          </div>
        </div>
      </div>
    )
  }, []);

  return (
    <>
      <UsersSummary
        statistics={usersGridUIStore.statistics}
        pageQueryStore={usersGridUIStore.requestParams}
      />

      <div className={styles.tableActions}>
        <div className={styles.invite}>
          <h4 className={styles.h4}>{messages['usersPage.title']}</h4>

          {
            userPermissionsUIStore.canCreateUser && (
              <span style={{ display: 'inherit' }} title={messages['usersPage.inviteUser.title']}>
                <AddOutlinedIcon
                  style={{ fontSize: 24 }}
                  className={styles.addIcon}
                  onClick={onInviteUserModalOpen}
                />
              </span>
            )
          }
        </div>
        <div className={styles.filters}>
          <Observer>
            {() => (
              <>
                <SearchForm
                  initialValues={{
                    search: usersGridUIStore.requestParams.getStorageParams
                      ? usersGridUIStore.requestParams.getStorageParams.searchText
                      : '',
                  }}
                  onSearchTextChanged={onSearchTextChanged}
                  className={styles.searchWrapper}
                  disabled={usersGridUIStore.status.isLoading}
                />
              </>
            )}
          </Observer>

          <span style={{ display: 'inherit' }} title={messages['usersPage.filter.title']}>
            <FilterListOutlinedIcon
              onClick={() => toggleFiltersModalOpen(true)}
              className={styles.filterIcon}
            />
          </span>
        </div>
        <UsersFiltersModal
          isOpen={filtersModalOpen}
          onRequestClose={() => toggleFiltersModalOpen(false)}
        />
      </div>
      <UsersFiltersBar />
      <Observer>
        {() => {
          const data = usersGridUIStore.users.toJS();
          const preparedData = data.map(row => ({
            ...row,
            subRows: [{
              email: row.email,
              groups: row.groups,
              procedures: row.procedures,
            }],
          }));

          return (
            <>
              <UserModal
                isOpen={userUIStore.userModalOpened}
                onRequestClose={onInviteUserModalClose}
              />
              <ResendInviteModal
                isOpen={userUIStore.resendInvitationModalOpened}
                onRequestClose={onResendInviteUserModalClose}
              />
              {/* TODO move logic of modals to separate component */}
              <ConfirmationModal
                isOpen={userUIStore.deleteConfirmationModalOpened}
                message={messages['usersPage.delete.message']}
                confirmText={messages['usersPage.delete.confirmText']}
                onDecline={onDeleteUserDecline}
                onConfirm={onDeleteUserConfirm}
              />

              {
                usersGridUIStore.isTableVisible && (
                  <Table
                    data={preparedData}
                    columns={columns}
                    fetchData={onFetchData}
                    isLoading={usersGridUIStore.status.isLoading}
                    renderRowSubComponent={renderRowSubComponent}
                    paginationStatus={{...usersGridUIStore.pagination}}
                    initialState={initialSortState}
                  />
                )
              }

              {
                usersGridUIStore.noFilteringResults && <NoResultsView entityName='users' />
              }

              {
                usersGridUIStore.noItemsInGroup && <NoUsersInGroup />
              }
            </>
          )
        }}
      </Observer>
    </>
  )
}
