import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const DownloadableVersionFileStoreInferred = types.model('DownloadableVersionFileStore', {
  id: types.identifier,
  version: types.optional(types.string, ''),
  releaseNotes: types.maybeNull(types.string),
  platform: types.optional(types.string, ''),
  fileName: types.optional(types.string, ''),
  createdAt: types.maybeNull(types.string)
});

type DownloadableVersionFileStoreFactoryType = typeof DownloadableVersionFileStoreInferred;
interface IDownloadableVersionFileStoreFactory extends DownloadableVersionFileStoreFactoryType {}
export const DownloadableVersionFileStore: IDownloadableVersionFileStoreFactory = DownloadableVersionFileStoreInferred;
export interface IDownloadableVersionFileStore extends Instance<IDownloadableVersionFileStoreFactory> {}
export interface IDownloadableVersionFileStoreSnapshotIn extends SnapshotIn<IDownloadableVersionFileStore> {}
