import { Instance, SnapshotIn, types, flow, getEnv} from 'mobx-state-tree';
import { DownloadablesDataStore } from './Downloadables.store';
import { GetDownloadablesParams } from 'Download/service/getDownloadables';
import { ToastId } from 'react-toastify';

import { ProgressEvent } from 'superagent';
export const DOWNLOADABLES_SEARCH_TEXT_KEY = 'downloadables_search_text';

export const DownloadablesUIStoreInferred = types
  .model('DownloadablesUIStore', {
    _downloadables: types.optional(DownloadablesDataStore,{}),
    searchText: types.optional(types.string, ''),
    isLoading: types.optional(types.boolean, false)
  })
  .actions(self => {
    const loadDownloadables = flow(function* () {
      // Default params
      const payload:GetDownloadablesParams = {
        limit:100,
        page:0,
        searchText:self.searchText
      }

      self.isLoading = true;
      yield self._downloadables.load(payload);
     
      self.isLoading = false;
    });
    const getFirstDovnloadableVersion = (idGroup:string,idDownloadable:string) => {
      let downloadableGroup=self._downloadables.downloadableGroups.find(dg=>dg.id==idGroup);
      if(downloadableGroup){
        let downloadable=downloadableGroup.downloadables.find(d=>d.id==idDownloadable);
        if(downloadable){
          return downloadable.downloadableVersionFiles[0];
        }
      }
      return null;
    };
    const downloadDownloadable = (id: string,fileName:string,toastId:ToastId,progressCallback?:(e:ProgressEvent)=>void) => {
      self._downloadables.selectedId = id;
      self._downloadables.selectedFileName=fileName;
      self._downloadables.download(fileName,toastId,progressCallback);
    }
    const changeSearchText=(search:string) =>{
      self.searchText=search;
      loadDownloadables();
    }
    const saveSearchTextToStorage = () => {
      sessionStorage.setItem(DOWNLOADABLES_SEARCH_TEXT_KEY, JSON.stringify(self.searchText));
    }
    const search = (searchText: string) => {
      self.searchText = searchText;
      saveSearchTextToStorage();
    }
    return {
      loadDownloadables,
      downloadDownloadable,
      changeSearchText,
      getFirstDovnloadableVersion,
      search
    }
  })
  .views(self => {

    return {
      get downloadableGroups() {
        return self._downloadables.downloadableGroups;
      },
      get pagination() { return self._downloadables.paginationStatus; },
      get isSearchApplied () {
        return !!self.searchText;
      },
      get statistics() { var count=0;
        self._downloadables.downloadableGroups.forEach(g => g.downloadables.forEach(d =>count+=d.downloadableVersionFiles.length));
        return count;  
       },
      get getStorageSearchText() {
        const storedSearchText = sessionStorage.getItem(DOWNLOADABLES_SEARCH_TEXT_KEY);

        return storedSearchText && JSON.parse(storedSearchText);
      },
    }
  });

type DownloadablesUIStoreFactoryType = typeof DownloadablesUIStoreInferred;
interface IDownloadablesUIStoreFactory extends DownloadablesUIStoreFactoryType {}
export const DownloadablesUIStore: IDownloadablesUIStoreFactory = DownloadablesUIStoreInferred;
export interface IDownloadablesUIStore extends Instance<IDownloadablesUIStoreFactory> {}
export interface IDownloadablesUIStoreSnapshotIn extends SnapshotIn<IDownloadablesUIStore> {}
