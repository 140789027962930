import React, { useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import _ from 'lodash';

import ArrowRightIcon from '@assets/arrow-right.svg';

import { useStyles } from './DownloadGroup.styles';
import { useDownloadablesUIStore } from '@core/useStores';
import { messages } from 'Download/download.messages';
import { Observer } from 'mobx-react';
import { Table } from '@shared/components/Table';
import { NoResultsView } from '@shared/components/NoResultsView';
import { Cell, Column } from 'react-table';
import classNames from 'classnames';
import DownloadIcon from '@assets/download.png';
import { DATE_MMM_DD_YYYY } from '@shared/constants';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ToastMessage } from '@shared/components/Toast';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { ProgressEvent } from 'superagent';
type SubComponentProps = {
  row: {
    originalSubRows: Array<{
      description: string,
      releaseNotes: string|undefined,
      olderDownloadableVersionFile:DownloadableVersionFileProps[]
    }>,
  }
}
type SubComponentInternalProps = {
  row: {
    originalSubRows: Array<{
        releaseNotes: string|undefined
    }>,
  }
}
const EXPANDER_CELL_WIDTH = 24;

const NoInfo = () => {
  const styles = useStyles();
  return <div className={styles.noInfo}>{messages['downloadPage.noInfo']}</div>
}

const columnsData = [
  {
    id: 'expander',
    minWidth: 40,
    maxWidth: 40,
    Header: () => (
      <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
    ),
    Cell: ({ row }: Cell) => {
      const { style, ...props } = row.getToggleRowExpandedProps();
      const styles = useStyles();

      return (
        <div
          {...props}
          style={{
            ...style,
            minWidth: EXPANDER_CELL_WIDTH,
            display: 'flex',
          }}
        >
          {row.canExpand && (
            <img
              src={ArrowRightIcon}
              className={classNames(styles.expandIcon, { [styles.expanded]: row.isExpanded })}
            />
          )}
        </div>
      )
    },
    width: EXPANDER_CELL_WIDTH + 16,
    disableSortBy: true,
  },
  {
    Header: messages['downloadPage.table.header.name'],
    accessor: 'name',
    width: 18,
    maxWidth: 200,
    Cell: ({ row, value }: Cell) => {
      const styles = useStyles();
      
      return (
        <div className={styles.nameWrapper}>
            <span className={styles.downloadName}>{ value }</span>
        </div>
      );
    },
  },
  {
    Header: messages['downloadPage.table.header.description'],
    accessor: 'description',
    width: 18,
    maxWidth: 700,
    Cell: ({ row, value }: Cell) => {
      const styles = useStyles();
      
      return (
        <div className={styles.nameWrapper}>
            <span className={styles.downloadName}>{ value }</span>
        </div>
      );
    },
  },
  
  {
    Header: messages['downloadPage.table.header.platform'],
    accessor: 'platformLast',
    width: 18,
    maxWidth: 140,
    Cell: ({ value }: Cell) => value || <NoInfo />,
  },
  {
    Header: messages['downloadPage.table.header.date'],
    accessor: 'dateLast',
    width: 18,
    maxWidth:120,
    Cell: ({ value }: Cell) => value ? moment(value.replace("T23:59:59Z","T12:00:00Z")).format(DATE_MMM_DD_YYYY) : <></>,
  },
  {
    Header: messages['downloadPage.table.header.version'],
    accessor: 'versionLast',
    width: 12,
    maxWidth: 75,
    Cell: ({ value }: Cell) => value || <NoInfo />,
  },
  {
    id: 'download',
    minWidth: 40,
    maxWidth: 40,
    disableSortBy: true,
    Cell: ({ row }: Cell) => {
      const styles = useStyles();

      const downloadablesUIStore = useDownloadablesUIStore();
      const original: Record<string, any> = row.original;

      const onCellClick = () => {
        
        if(original.fileNameLast&&original.fileNameLast!='empty'){
          let toastId=toast.success(
            <ToastMessage
              message={'Downloading '+original.fileNameLast+'...'}
              type='success'
            />,
            { autoClose: 180000 }
          );
          downloadablesUIStore.downloadDownloadable(original.idLast,original.fileNameLast,toastId,
            (e:ProgressEvent)=>{
              toast.update(toastId,{render:()=>
                <ToastMessage
                  message={Math.round(e.percent??0)+'% - Downloading '+original.fileNameLast}
                  type='success'
                />})
            }
          );
        }else{
          toast.error(
            <ToastMessage
              message={'Missing file'}
              type='error'
            />,
            { autoClose: 1000 }
          );
        }
      }

      return (
        <button
          onClick={() => onCellClick()}
          className={styles.button}
          title={messages['downloadPage.download']}
        >
          <img src={DownloadIcon} style={{width:24,height:24}}/>
        </button>
      )
    },
  },
]

const columnsInternalData = [
    {
      id: 'expander',
      minWidth: 40,
      maxWidth: 40,
      Header: () => (
        <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
      ),
      Cell: ({ row }: Cell) => {
        const { style, ...props } = row.getToggleRowExpandedProps();
        const styles = useStyles();
  
        return (
          <div
            {...props}
            style={{
              ...style,
              minWidth: EXPANDER_CELL_WIDTH,
              display: 'flex',
            }}
          >
            {row.canExpand && (
              <img
                src={ArrowRightIcon}
                className={classNames(styles.expandIcon, { [styles.expanded]: row.isExpanded })}
              />
            )}
          </div>
        )
      },
      width: EXPANDER_CELL_WIDTH + 16,
      disableSortBy: true,
    },
    {
        Header: messages['downloadPage.table.header.releaseNotes'],
        accessor: 'releaseNotes',
        width: 12,
        maxWidth: 600,
        Cell: ({ value }: Cell) => value || <NoInfo />,
    },
    {
      Header: messages['downloadPage.table.header.platform'],
      accessor: 'platform',
      width: 12,
      maxWidth: 130,
      Cell: ({ value }: Cell) => value || <NoInfo />,
    },
    {
      Header: messages['downloadPage.table.header.date'],
      accessor: 'date',
      width: 18,
      maxWidth:120,
      Cell: ({ value }: Cell) => value ? moment(value.replace("T23:59:59Z","T12:00:00Z")).format(DATE_MMM_DD_YYYY) : <></>,
    },
    {
      Header: messages['downloadPage.table.header.version'],
      accessor: 'version',
      width: 10,
      maxWidth: 80,
      Cell: ({ value }: Cell) => value || <NoInfo />,
    },
    {
      id: 'download',
      minWidth: 40,
      maxWidth: 40,
      disableSortBy: true,
      Cell: ({ row }: Cell) => {
        const styles = useStyles();
  
        const downloadablesUIStore = useDownloadablesUIStore();
        const original: Record<string, any> = row.original;
  
        const onCellClick = () => {
       
          if(original.fileName&&original.fileName!='empty'){
            let toastId=toast.success(
              <ToastMessage
                message={'Downloading '+original.file+'...'}
                type='success'
              />,
              { autoClose: 180000 }
            );
            downloadablesUIStore.downloadDownloadable(original.id,original.fileName,toastId,
              (e:ProgressEvent)=>{
                toast.update(toastId,{render:()=>
                  <ToastMessage
                    message={Math.round(e.percent??0)+'% - Downloading '+original.fileNameLast}
                    type='success'
                  />})
              }
            );
          }else{
            toast.error(
              <ToastMessage
                message={'Missing file'}
                type='error'
              />,
              { autoClose: 1000 }
            );
          }
        }
  
        return (
          <button
            onClick={() => onCellClick()}
            className={styles.button}
            title={messages['downloadPage.download']}
          >
            <img src={DownloadIcon} style={{width:24,height:24}}/>
          </button>
        )
      },
    },
  ]
export type DownloadableVersionFileProps = {
    id:string,
    version:string,
    releaseNotes: string|null,
    platform: string,
    fileName: string,
    date:string|null
}
export type DownloadableProps = {
    name:string,
    description:string,
    olderDownloadableVersionFile:DownloadableVersionFileProps[],
    idLast?:string,
    versionLast?:string,
    releaseNotesLast?: string|null,
    platformLast?: string,
    fileNameLast?: string,
    dateLast:string|null
}

export type DownloadGroupProps = {
    name:string,
    description:string,
    downloadables:DownloadableProps[]    
}

export const DownloadGroup: React.FC<DownloadGroupProps> = ({name,description,downloadables}) => {
  const downloadablesUIStore = useDownloadablesUIStore();
  const theme = useTheme();
  const styles = useStyles({ theme });
  const renderRowSubComponentInternal = React.useCallback(({row}: SubComponentInternalProps) => {
    const dataInternal = row.originalSubRows[0];
    return (
      <div className={styles.bodySubComponent}>
        <div className={styles.descriptionWrapper}>
          <h5 className={styles.header}>{messages['downloadPage.subRow.releaseNotes']}</h5>
          <div className={styles.text}>
            {dataInternal.releaseNotes || <NoInfo />}
          </div>
        </div>
    </div>
    )
  }, []);
  const columnsInternal: Column<object>[] = React.useMemo(() => columnsInternalData, []);
  const renderRowSubComponent = React.useCallback(({ row }: SubComponentProps) => {
    const data = row.originalSubRows[0];
    const preparedDataInternal = data.olderDownloadableVersionFile&&data.olderDownloadableVersionFile.length>0&&data.olderDownloadableVersionFile.map(row => ({
      ...row,
      subRows: [{
        releaseNotes: row.releaseNotes
      }],
    }));
   
    
    return (
      <div className={styles.bodySubComponent}>
        <div className={styles.descriptionWrapper}>
          <h5 className={styles.header}>{messages['downloadPage.subRow.description']}</h5>
          <div className={styles.text}>
            {data.description || <NoInfo />}
          </div>
        </div>
        {
        data.releaseNotes&&(<div className={styles.descriptionWrapper}>
          <h5 className={styles.header}>{messages['downloadPage.subRow.releaseNotes']}</h5>
          <div className={styles.text}>
            {data.releaseNotes}
          </div>
        </div>
        )
        }
        {
            preparedDataInternal&&(
                <div className={styles.descriptionWrapper}>
                    <h5 className={styles.header}>{messages['downloadPage.subRow.olderVersions']}</h5>
                    <Table
                        className={styles.subTable}
                        data={preparedDataInternal}
                        columns={columnsInternal}
                        isLoading={downloadablesUIStore.isLoading}
                        renderRowSubComponent={renderRowSubComponentInternal}
                        paginationDisabled={true}
                        paginationStatus={{...downloadablesUIStore.pagination}}
                        initialState={initialSortState}
                    />
               </div>
            )
        }
       
      </div>
    )
  }, []);
  const initialSortState = {
    sortBy: [],
  };
  const columns: Column<object>[] = React.useMemo(() => columnsData, []);

  return (
    <>
      <Observer>
        {() => {
          const data = downloadables;
          const preparedData = data.map(row => ({
            ...row,
            subRows: [{
              description: row.description,
              releaseNotes: row.releaseNotesLast,
              olderDownloadableVersionFile:row.olderDownloadableVersionFile
            }],
          }));

          return (
            <>
                <div className={styles.groupWrapper}>
                    <div className={styles.h4}>{name}</div>
                    <div className={styles.text}>{description}</div>
                </div>
              {
                 downloadables&&downloadables.length>0 && (
                  <Table
                    className={styles.tableFlexible}
                    data={preparedData}
                    columns={columns}
                    isLoading={downloadablesUIStore.isLoading}
                    renderRowSubComponent={renderRowSubComponent}
                    paginationDisabled={true}
                    paginationStatus={{...downloadablesUIStore.pagination}}
                    initialState={initialSortState}
                  />
                )
              }

              {
                (!downloadables||downloadables.length==0) && <NoResultsView entityName='downloads' />
              }
            </>
          );
        }}
      </Observer>
    </>
  )
}
